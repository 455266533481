import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { FlowElements } from '../../Hooks/useFlow';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  createEmptyStateViewModel,
  EmptyStateViewModel,
} from '../emptyStateViewModel/emptyStateViewModel';
import { CalendarErrors } from '../../../../types/types';

export enum BottomSectionStatus {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

export type WidgetViewModel = {
  status: BottomSectionStatus;
  errorNotificationText: string;
  focusedElement?: FlowElements;
  emptyStateViewModel?: EmptyStateViewModel;
};

export const memoizedWidgetViewModel: MemoizedViewModalFactory<WidgetViewModel> =
  {
    dependencies: {
      state: ['calendarErrors', 'focusedElement', 'bottomSectionStatus'],
    },
    createViewModel: createWidgetViewModel,
  };

export function createWidgetViewModel({
  state,
  context: { t },
}: ViewModelFactoryParams<CalendarState, CalendarContext>): WidgetViewModel {
  const { calendarErrors, focusedElement, bottomSectionStatus, initialErrors } =
    state;

  let emptyStateViewModel;
  if (initialErrors.length > 0) {
    emptyStateViewModel = createEmptyStateViewModel({
      t,
      type: initialErrors[0],
    });
  }

  const errorNotificationText = getErrorNotificationText(calendarErrors, t);

  return {
    status: bottomSectionStatus,
    errorNotificationText,
    focusedElement,
    emptyStateViewModel,
  };
}

const getErrorNotificationText = (
  calendarErrors: CalendarErrors[],
  t: TFunction,
) => {
  if (
    calendarErrors.includes(
      CalendarErrors.SELECTED_SLOT_VALIDATION_NO_TIME_SELECTED_ERROR,
    )
  ) {
    return t('app.notification.no-time-selected-error.text');
  }
  return '';
};
